import React, { Component } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import LayoutContainer from 'components/layout/LayoutContainer';
import { Breadcrumbs } from 'components/widgets';

class PrivacyPolicySOSPage extends Component {
	state = {
		routes: [
			{
				name: 'Inicio',
				pathname: '/',
				asPath: '/'
			},
			{
				name: 'Política de privacidad',
				pathname: Router?.router?.pathname,
				asPath: Router?.router?.asPath
			}
		]
	};

	render() {
		return (
			<LayoutContainer
				ocult={
					Router.router?.asPath === '/m/terminos-uso-miembros'
						? true
						: this.props.ocult
				}
			>
				{Router.router?.asPath === '/politica-privacidad' && (
					<div className="mt-24">
						<Breadcrumbs routes={this.state.routes} />
					</div>
				)}
				<div
					className={this.props.isDialog !== true ? 'container pb-12 pt-4' : ''}
				>
					<Head>
						<meta name="robots" content="noindex" />
					</Head>
					<p className={`text-center font-poppins`}>
						<b>PRIVACIDAD Y USO DE DATOS PERSONALES</b>
					</p>
					<p className={`text-center font-poppins mb-4`}>
						<b>MEMBRESÍA ASISTENSI Y ASISTENSI SOS</b>
					</p>
					<div className="w-full text-base leading-relaxed">
						<p className="my-4">
							La Política de privacidad le proporciona información sobre la
							recopilación y el uso de la Información Personal que se
							proporciona cuando navega por nuestra página web y/o App y cuando
							utiliza el beneficio de Orientación Médica Virtual (en adelante
							“ASISTENSI SOS”).
						</p>
						<p className="my-4">
							Para la creación de la cuenta, el usuario deberá suministrar
							algunos datos, los cuales serán almacenados a través de la página
							web de nuestra propiedad{' '}
							<a href="www.asistensi.com" className="font-bold" target="_blank">
								www.asistensi.com
							</a>
							. Además para acceder al beneficio ASISTENSI SOS, el usuario
							deberá suministrar algunos datos, los cuales serán almacenados
							temporalmente para recibir una respuesta electrónica o en caso de
							urgente necesidad debido al estado de salud, la información se
							enviará a un médico para su revisión y criterio médico a través de
							la página web de nuestra propiedad.
						</p>
						<p className="my-4">
							En caso de suscribir a algún familiar como Miembro, deberán
							obtener el consentimiento del mismo bajo su responsabilidad. Para
							el caso de los menores de edad sólo podrán inscribirlos y utilizar
							el beneficio a través de las personas que tengan la patria
							potestad sobre ellos.
						</p>
						<p className="my-4">
							La presente política de privacidad fue creada para ayudarle a
							entender cómo se recopila y almacena su información.
						</p>

						<div className="ml-8 font-bold">
							1. ¿Qué Información Personal recopila ASISTENSI?
						</div>
						<p className="my-4">
							Asistensi recopila la información suministrada por el usuario al
							momento de crear una cuenta en la página web o App, que es
							necesaria para obtener la Membresía, tal como: nombre, apellido,
							fecha de nacimiento y dirección de correo electrónico. Además
							recopila los datos personales cuando el usuario se registra para
							ASISTENSI SOS a través de un formulario de la página web Asistensi
							https://asistensi.com.do/perfil/sos. Para ello, el usuario deberá
							suministrar datos de salud rellenando el respectivo cuestionario
							con el fin de proporcionarle una Orientación Médica Virtual o
							dependiendo del estado de salud, una respuesta por parte del
							médico en cuestión acorde a su criterio y datos otorgados para
							ello.
						</p>
						<p className="my-4">
							Adicionalmente, cuando el usuario visita nuestra página web,
							recopilamos información de registro estándar y, a través de
							cookies, detalles de su comportamiento en línea, siempre que nos
							haya dado su consentimiento, ya sea al continuar navegando o al
							aceptar explícitamente el uso de cookies. Usaremos estos datos
							para analizar y mejorar la experiencia de navegación de los
							usuarios. Puede acceder a nuestra Política de Cookies aquí{' '}
							<a
								href="https://www.asistensi.com/politica-cookies"
								target="_blank"
								className="font-bold"
							>
								https://www.asistensi.com/politica-cookies
							</a>
							, en este sentido, se recomienda revisar estos términos
							periódicamente.
						</p>
						<p className="my-4">
							En caso de contratación del PLAN, respecto a los medios de pago,
							ASISTENSI no almacena datos confidenciales ya que se hace a través
							de proveedores externos de plataformas de pagos (p.ej., stripe,
							paypal).
						</p>
						<div className="ml-8 font-bold">
							2. ¿Quién es el Responsable del tratamiento?
						</div>
						<p className="font-bold ml-4"></p>
						<p className="my-4">
							Identidad del Responsable: Asistensi Global Insurance Inc.
						</p>
						<p className="my-4">
							Domicilio: Level 3, Cedar Court, Wildey Business Park, Wildey, St.
							Michael, Barbados BB14006, inscrito en el Registro de Barbados
							bajo el número 44330 y número TIN 1000222885424
						</p>
						<p className="my-4">
							Correo electrónico:{' '}
							<a
								htef="mailto:dpo@asistensi.com"
								className="underline font-bold cursor-pointer"
							>
								dpo@asistensi.com
							</a>
						</p>
						<p className="my-4">
							Asistensi, es propietaria de este sitio web, cumple
							escrupulosamente con toda la normativa de protección de datos que
							es aplicable y se han implementado todas las medidas técnicas y
							organizativas necesarias para garantizar el nivel de seguridad
							adecuado.
						</p>
						<p className="my-4">
							Si tiene alguna duda acerca de esta política se podrá poner en
							contacto con el Delegado de Protección de Datos:{' '}
							<a
								htef="mailto:dpo@asistensi.com"
								className="underline font-bold cursor-pointer"
							>
								dpo@asistensi.com
							</a>{' '}
							quien tratará de resolver cualquier duda que Usted tenga.
						</p>
						<div className="ml-8 font-bold">
							3. ¿Con qué finalidad trata la información personal ASISTENSI?
						</div>
						<ul className="my-4">
							<li className="ml-4 my-4">
								- Asistensi utiliza la información personal suministrada por los
								usuarios para que puedan acceder y registrarse en la página web
								y/o App y obtener los beneficios de ASISTENSI SOS.
							</li>
							<li className="ml-4 my-4">
								- Asistensi utilizará la información personal para dar los
								servicios adquiridos y tramitar los beneficios de ASISTENSI SOS.
							</li>
							<li className="ml-4 my-4">
								- Asistensi utilizará la información personal con el fin de
								gestionar consultas, dudas o requerimientos de información
								adicional.
							</li>
							<li className="ml-4 my-4">
								- Asistensi utilizará la información personal con el fin de
								analizar el uso de la página web y/o App y mejorar el
								rendimiento, para más información visita la política de cookies.
							</li>
						</ul>
						<div className="ml-8 font-bold">
							4. ¿Cómo recopila información ASISTENSI?
						</div>
						<p className="my-4">
							Los datos personales se recopilan mediante un formulario a través
							de la página web de ASISTENSI{' '}
							<a
								href="https://asistensi.com/"
								className="font-bold cursor-pointer"
								target="_blank "
							>
								{' '}
								https://www.asistensi.com/
							</a>
							. No se recopilará ningún dato hasta que no se acepte expresamente
							la política de privacidad.
						</p>
						<p className="my-4">
							Los datos se almacenan en Amazon Web Services, con domicilio en
							Estados Unidos de América, en un fichero administrado por
							ASISTENSI.
						</p>

						<div className="ml-8 font-bold">
							5. ¿Por qué ASISTENSI almacena información?
						</div>
						<p className="my-4">
							Almacenamos información para mejorar y optimizar nuestros
							procesos, con el fin de prestar un mejor servicio a nuestros
							usuarios.
						</p>
						<p className="my-4">
							En el caso de datos de salud, se almacenan por motivos de
							elegibilidad del PLAN ASISTENSI y poder autorizar dicha
							contratación. Además el tratamiento de la información personal
							está basado en la relación contractual o precontractual
							establecida con Asistensi y necesaria para proporcionar la
							prestación de Orientación Médica Virtual y su consentimiento
							expreso para tratar los datos de salud proporcionados con los
							fines indicados.
						</p>

						<div className="ml-8 font-bold">
							6. ¿Cuánto tiempo almacena información ASISTENSI?
						</div>
						<p className="my-4">
							ASISTENSI almacena información durante la vigencia de la Membresía
							de la persona física y en caso de haber contratado el PLAN
							ASISTENSI, se almacenará durante la vigencia del PLAN y acorde al
							periodo establecido en la normativa vigente para datos de salud.
						</p>

						<p className="my-4">
							En el caso de ASISTENSI SOS, los datos personales serán tratados
							mientras la relación contractual y/o precontractual se encuentre
							en vigor y/o siga siendo necesario para la finalidad indicada.
							Cabe recalcar que los datos de salud se tratarán sólo
							temporalmente para los casos especificados en que el estado de
							salud sea delicado según el criterio médico y hasta que sea
							resuelto el caso presentado mediante el cuestionario de ASISTENSI
							SOS.
						</p>

						<div className="ml-8 font-bold">
							7. ¿Cómo protege su Información Personal ASISTENSI?
						</div>
						<p className="my-4">
							Asistensi reconoce la importancia de la seguridad, integridad y
							confidencialidad de su información. Se han implementado medidas de
							seguridad técnicas, administrativas y físicas que están diseñadas
							para proteger la información de los usuarios contra el acceso, el
							uso y la modificación no autorizados. Periódicamente, se revisan
							los procedimientos de seguridad para considerar nuevas tecnologías
							y métodos adecuados. Tenga en cuenta que, a pesar de los mejores
							esfuerzos, ninguna medida de seguridad es perfecta ni
							impenetrable. Retendremos su información personal durante el
							período de tiempo necesario para cumplir los propósitos que se
							describen en esta política de privacidad, a menos que se requiera
							un período de retención mayor o que lo permita la ley.
						</p>

						<div className="ml-8 font-bold">
							8. ¿ASISTENSI realiza Transferencias internacionales?
						</div>
						<p className="my-4">
							La información personal será transferida y almacenada en Amazon
							Web Services, con domicilio en Estados Unidos de América, en un
							fichero administrado por Asistensi. Igualmente, en el caso de
							transferir la información personal a terceros en otras partes del
							mundo con los fines descritos en esta política de privacidad, se
							garantizarán todas las medidas adecuadas para proteger la
							confidencialidad de la misma. Estas medidas pueden incluir la
							implementación de cláusulas contractuales estándar en la medida
							reconocida por la ley, contratos, la obtención de su
							consentimiento u otros medios legales para la transferencia de
							información personal.
						</p>

						<div className="ml-8 font-bold">
							9. ¿Quién tiene acceso a su Información Personal?
						</div>
						<div className="my-4">
							Asistensi puede compartir su Información Personal en los
							siguientes casos:
							<ul className="mt-4">
								<li className="ml-8">
									a) Para poder proveer nuestros productos y servicios
									contratados con nuestras entidades asociadas incluido el
									servicio de Orientación Médica Virtual a través de un médico
									de nuestra red de proveedores;
								</li>
								<li className="ml-8">
									b)Adicionalmente Asistensi podrá compartir su información con
									las demás empresas matrices actuales o futuras, subsidiarias,
									empresas conjuntas u otras empresas bajo un control común
									("Afiliadas"), en cuyo caso se le solicitará a las Afiliadas
									que respeten la presente Política de Privacidad.
								</li>
								<li className="ml-8">
									c) Se podrá ceder la información personal por razones de
									transformaciones estructurales societarias, como escisión,
									fusión o cesión global de activos y pasivos.
								</li>
								<li className="ml-8">
									d) Con fines publicitarios y de marketing;
								</li>
								<li className="ml-8">
									e) Con fines de cumplir con la normativa de Prevención y
									Blanqueo de Capitales;
								</li>
								<li className="ml-8">
									f) Podemos compartir su Información Personal a cualquier
									agencia de aplicación de la ley, tribunal, regulador,
									autoridad gubernamental u otro tercero cuando sea necesario
									para cumplir con una obligación legal o reglamentaria, o de
									otro modo para proteger nuestros derechos o los derechos de
									cualquier tercero; y/ó en algunos casos, Asistensi necesitará
									proveedores para que traten datos a su nombre, pero solo
									utilizarán dichos datos según las instrucciones
									proporcionadas. Por ejemplo, en caso de necesitar un proveedor
									para almacenar los datos recabados en este sitio web o la App.
								</li>
							</ul>
						</div>

						<div className="ml-8 font-bold">10. Derechos del usuario</div>
						<div className="my-4">
							De acuerdo con la normativa aplicable en su país o zona
							geográfica, Usted tiene derecho a:
							<ul className="mt-4">
								<li className="list-disc ml-8">
									Saber si sus datos personales están siendo tratados y, en su
									caso, acceder a ellos;
								</li>
								<li className="list-disc ml-8">
									Rectificar sus datos personales si fueran inexactos o
									incompletos;
								</li>
								<li className="list-disc ml-8">
									Solicitar la eliminación de sus datos si no son necesarios
									para los fines indicados;
								</li>
								<li className="list-disc ml-8">
									Exigir que se delimite el procesamiento de sus datos
									personales;
								</li>
								<li className="list-disc ml-8">
									Transferir sus datos personales a otro proveedor de su
									elección (portabilidad); y
								</li>
								<li className="list-disc ml-8">
									Oponerse al tratamiento de sus datos personales en cualquier
									momento.
								</li>
							</ul>
						</div>
						<p className="my-4">
							Para ello, deberá enviar una solicitud acompañada, en aquellos
							casos que consideremos necesario, de un documento de identidad a{' '}
							<a href="mailto:dpo@asistensi.com">dpo@asistensi.com</a>.
						</p>
						<p className="my-4">
							Asimismo, puede presentar una reclamación ante la Autoridad de
							Protección de Datos correspondiente si cree que se han infringido
							sus derechos.
						</p>

						<div className="ml-8 font-bold">11. Ley Aplicable</div>
						<p className="my-4">
							Cualquier duda o conflicto derivado de los presentes términos y
							condiciones, privacidad y uso de datos personales será
							interpretado de conformidad con las leyes de Barbados y cualquier
							disputa deberá someterse ante los Tribunales de la ciudad de
							Bridgetown, a cuya jurisdicción las partes declaran someterse.
						</p>
						<p className="my-4">
							Para cualquier duda en los Términos de Uso y Política de
							Privacidad de la página web Usted puede consultarlos en{' '}
							<span>
								<a
									href="https://www.asistensi.com/terminos-uso"
									className="underline font-bold cursor-pointer"
									target="_blank "
								>
									https://www.asistensi.com/terminos-uso{' '}
								</a>{' '}
							</span>
							y{' '}
							<a
								href="https://www.asistensi.com/politica-privacidad"
								className="underline font-bold cursor-pointer"
								target="_blank "
							>
								https://www.asistensi.com/politica-privacidad
							</a>
						</p>
						<div className="ml-8 font-bold">12. Seguridad</div>
						<p className="my-4">
							ASISTENSI reconoce la importancia que tienen los datos personales
							de sus usuarios y por eso toma las medidas técnicas y
							organizacionales adecuadas para garantizar el resguardo de los
							mismos. Estas medidas incluyen: cifrado con protocolo SSL / TLS
							v2, firewall a nivel de servidor, almacenamiento cifrado, entre
							otros.
						</p>

						<div className="ml-8 font-bold">13. Enmiendas</div>
						<p className="my-4">
							ASISTENSI se reserva el derecho de introducir cambios en la página
							web, en los presentes Términos y Condiciones, Política de
							Privacidad y Política de Cookies en cualquier momento sin previo
							aviso notificando a los usuarios de dichos cambios. En este
							sentido, se recomienda revisar estos términos periódicamente.
						</p>
						<p className="my-4 flex justify-end">
							Última actualización: <b>1 de Octubre de 2021</b>
						</p>
					</div>
				</div>
			</LayoutContainer>
		);
	}
}

export default PrivacyPolicySOSPage;
